import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppStore } from "app/_shared/stores/app.store";
import { Permission } from "app/_shared/types/models/role";
import { AppUser } from "app/_shared/types/models/user";
import { environment } from "environments/environment";
import { map, filter } from "rxjs";
import { injectQuery } from "@ngneat/query";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  apiUrl: string = environment.api + "auth";

  constructor(
    private http: HttpClient,
    private appStore: AppStore,
  ) {}

  #query = injectQuery();
  loadPermissions() {
    return this.#query({
      queryKey: ["permissions"] as const,
      queryFn: () => {
        return this.http.get<Permission[]>(`${this.apiUrl}/permissions`);
      },
    })
      .result$.pipe(filter(() => !!this.appStore.user()))
      .subscribe((result) => {
        if (result.error) {
          this.appStore.setState({
            error: true,
            status: result.error instanceof HttpErrorResponse ? result.error.status : 500,
            permissions: [],
          });
        }
        if (result.isSuccess) {
          this.appStore.setState({ error: false, permissions: result.data });
        }
      });
  }

  login(params: Partial<{ email: string; password: string }>) {
    return this.http.post<AppUser & { permissions: Permission[] }>(`${this.apiUrl}/login`, params).pipe(
      map((response) => {
        const { permissions, ...user } = response;
        if (user && user.token) {
          this.appStore.setState({ user, permissions });
        }
        return user as AppUser;
      }),
    );
  }

  resetRequest(email: string) {
    return this.http.post<void>(`${this.apiUrl}/reset-request`, { email });
  }

  reset(token: string, password: string) {
    return this.http.post<void>(`${this.apiUrl}/reset`, { token, password });
  }

  logout() {
    this.appStore.setState({ user: null });
  }
}
