import { Component, inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import { DocumentService } from "app/_shared/services/document.service";
import { UserService } from "app/_shared/services/user.service";
import { IUser, UserInputForm } from "app/_shared/types/models/user";
import { getBase64 } from "app/_shared/utils/download.utils";
import { UserEditComponentData } from "app/users/users/user-edit/user-edit.component";
import { head } from "lodash";
import { NzMessageService } from "ng-zorro-antd/message";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { debounceTime, distinctUntilChanged, finalize, switchMap } from "rxjs";

export interface ProfileEditComponentData {
  user: IUser;
}

@Component({
  selector: "app-profile-edit",
  templateUrl: "./profile-edit.component.html",
  styles: [],
})
export class ProfileEditComponent implements OnInit {
  readonly data: UserEditComponentData = inject(NZ_MODAL_DATA);

  editUserForm = this.fb.group({
    email: this.fb.nonNullable.control<string>(null, [Validators.required, Validators.email]),
    firstName: this.fb.nonNullable.control<string>(null, [Validators.required]),
    lastName: this.fb.nonNullable.control<string>(null, [Validators.required]),
    password: this.fb.nonNullable.control<string>(null, []),
    checkPassword: this.fb.control<string>(null, []),
    phones: this.fb.array([]),
    skype: this.fb.control<string>(null, []),
  });

  loading = false;

  passwordVisible = false;

  avatar: File;
  avatarUrl: string;

  constructor(
    private modal: NzModalRef,
    private messageService: NzMessageService,
    private fb: FormBuilder,
    private documentService: DocumentService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.data.user) {
      this.avatarUrl = this.data.user.avatar?.url;
      this.data.user.phones.forEach(() => this.addPhone());
      this.editUserForm.patchValue({
        email: this.data.user.email,
        firstName: this.data.user.firstName,
        lastName: this.data.user.lastName,
        password: "",
        checkPassword: "",
        phones: this.data.user.phones.map(({ type, number }) => ({ type, number })),
        skype: this.data.user.skype,
      });
      if (!this.data.user.phones.length) this.addPhone();
    }
    this.onPasswordChanged();
  }

  destroyModal(data?: IUser): void {
    this.modal.destroy(data);
  }

  edit() {
    Object.values(this.editUserForm.controls).forEach((control) => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    });
    if (!this.editUserForm.valid) return;
    const value = this.editUserForm.value as UserInputForm;
    this.loading = true;
    if (this.avatar) {
      this.documentService
        .upload([this.avatar])
        .pipe(
          switchMap((uploadedFiles) => {
            const uploadedFile = head(uploadedFiles);
            return this.userService.update(this.data.user._id, {
              ...value,
              avatar: {
                key: uploadedFile.key,
                name: uploadedFile.originalname,
                url: uploadedFile.location,
              },
            });
          })
        )
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: (data) => {
            this.messageService.success("Profile updated successfully.");
            this.destroyModal(data);
          },
        });
    } else {
      this.userService
        .update(this.data.user._id, value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: (data: IUser) => {
            this.messageService.success("Profile updated successfully.");
            this.destroyModal(data);
          },
        });
    }
  }

  get phones(): FormArray {
    return this.editUserForm.get("phones") as FormArray;
  }

  addPhone(): void {
    this.phones.push(
      this.fb.group({
        type: this.fb.control<string>("professional"),
        number: this.fb.control<string>(null),
      })
    );
  }

  removePhone(index: number): void {
    if (this.phones.length === 1) return;
    this.phones.removeAt(index);
  }

  onPasswordChanged() {
    this.editUserForm
      .get("password")
      .valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          this.editUserForm.get("checkPassword").reset();
          this.editUserForm.get("checkPassword").setValidators([Validators.required, this.confirmationValidator]);
        } else {
          this.editUserForm.get("checkPassword").clearValidators();
        }
        this.editUserForm.get("checkPassword").markAsDirty();
        this.editUserForm.get("checkPassword").updateValueAndValidity();
      });
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (control.value !== this.editUserForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  beforeUpload = (file: any): boolean => {
    this.avatar = file;
    getBase64(file, (img: string) => {
      this.avatarUrl = img;
    });
    return false;
  };
}
