import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDocument, UploadedFile } from "app/_shared/types/models/document";
import { download } from "app/_shared/utils/download.utils";
import { environment } from "environments/environment";
import { saveAs } from "file-saver";
import { concatMap, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  apiUrl: string = environment.api + "api/documents";
  private dataSubject: Subject<IDocument[]> = new Subject();

  constructor(private http: HttpClient) {}

  fetchAll() {
    let page = 1;
    const fetchDataRecursive = () => {
      return this.findAll(page).pipe(
        concatMap((data) => {
          if (data.length) {
            this.dataSubject.next(data);
            page++;
            return fetchDataRecursive();
          } else {
            if (page === 1) this.dataSubject.next([]);
            return [];
          }
        }),
      );
    };
    fetchDataRecursive().subscribe();
    return this.dataSubject.asObservable();
  }

  findAll(page: number) {
    return this.http.put<IDocument[]>(this.apiUrl, { page });
  }

  getAll(relatedModel?: string) {
    return this.http.get<IDocument[]>(`${this.apiUrl}?relatedModel=${relatedModel}`);
  }

  getOne(documentId: string) {
    return this.http.get<IDocument>(`${this.apiUrl}/${documentId}`);
  }

  create(formData: FormData) {
    return this.http.post<IDocument[]>(this.apiUrl, formData);
  }

  update(documentId: string, document: any) {
    return this.http.put<IDocument>(`${this.apiUrl}/${documentId}`, document);
  }

  delete(documentId: string) {
    return this.http.delete<IDocument>(`${this.apiUrl}/${documentId}`);
  }

  restore(documentId: string) {
    return this.http.patch<IDocument>(`${this.apiUrl}/${documentId}`, {});
  }

  upload(files: File[]) {
    const formData = new FormData();
    files.forEach((file) => formData.append("file", file));
    return this.http.post<UploadedFile[]>(`${this.apiUrl}/actions/upload`, formData);
  }

  download(document: IDocument) {
    return this.http
      .get(document?.location, {
        reportProgress: true,
        observe: "events",
        responseType: "blob",
        headers: {
          "content-type": document?.mimeType,
        },
      })
      .pipe(download((file) => saveAs(file, document.originalName)));
  }
}
