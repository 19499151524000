<form nz-form nzLayout="vertical" [formGroup]="editUserForm">
  <nz-form-item>
    <nz-form-label nzRequired nzFor="email">Email</nz-form-label>
    <nz-form-control>
      <input type="text" nz-input formControlName="email" id="email" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired nzFor="firstName">First name</nz-form-label>
    <nz-form-control>
      <input type="text" nz-input formControlName="firstName" id="firstName" upperCase />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired nzFor="lastName">Last name</nz-form-label>
    <nz-form-control>
      <input type="text" nz-input formControlName="lastName" id="lastName" upperCase />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="password">Password</nz-form-label>
    <nz-form-control>
      <nz-input-group [nzSuffix]="suffixTemplate">
        <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password" id="password" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="checkPassword">Confirm Password</nz-form-label>
    <nz-form-control>
      <nz-input-group [nzSuffix]="suffixTemplate">
        <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="checkPassword" id="checkPassword" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <div formArrayName="phones">
    @for (phoneForm of phones.controls; track index; let index = $index; let first = $first; let last = $last) {
      <nz-form-item>
        <div [formGroupName]="index">
          @if (first) {
            <nz-form-label>Phones</nz-form-label>
          }
          <nz-form-control>
            <nz-input-group nzCompact>
              <nz-select class="!w-2/6 !border-r-0" formControlName="type">
                <nz-option nzLabel="Professional" nzValue="professional" />
                <nz-option nzLabel="Private" nzValue="private" />
              </nz-select>
              <input class="!w-3/6" formControlName="number" nz-input placeholder="Phone number" />
              @if (last) {
                <div class="!w-1/6 flex items-center justify-between !border-r-0">
                  @if (!first) {
                    <button class="w-1/2 bg-gainsboro-100" nz-button nzType="default" (click)="removePhone(index)">
                      <span nz-icon nzType="minus" nzTheme="outline"></span>
                    </button>
                  }
                  <button [class.!w-full]="first" class="w-1/2 bg-gainsboro-100" nz-button nzType="default" (click)="addPhone()">
                    <span nz-icon nzType="plus" nzTheme="outline"></span>
                  </button>
                </div>
              } @else {
                <button class="!w-1/6 bg-gainsboro-100" nz-button nzType="default" (click)="removePhone(index)">
                  <span nz-icon nzType="minus" nzTheme="outline"></span>
                </button>
              }
            </nz-input-group>
          </nz-form-control>
        </div>
      </nz-form-item>
    }
  </div>
  <nz-form-item>
    <nz-form-label nzFor="skype">Skype</nz-form-label>
    <nz-form-control>
      <input type="text" nz-input formControlName="skype" id="skype" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Avatar</nz-form-label>
    <nz-form-control>
      <nz-upload [nzBeforeUpload]="beforeUpload" nzListType="picture-card">
        @if (avatarUrl) {
          <img [src]="avatarUrl" alt="" fill />
        } @else {
          <span class="upload-icon" nz-icon nzType="plus"></span>
          <div class="ant-upload-text">Upload</div>
        }
      </nz-upload>
    </nz-form-control>
  </nz-form-item>
</form>
<div *nzModalFooter>
  <button nz-button nzType="default" nzSize="small" (click)="destroyModal()">Close</button>
  <button nz-button nzType="primary" nzSize="small" (click)="edit()" [nzLoading]="loading">Save</button>
</div>
<ng-template #suffixTemplate><i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i></ng-template>
