import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { store } from "app/_shared/stores/app.store";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf("s3") > -1) {
      return next.handle(request);
    }
    const currentUser = store.getValue().user;
    request = request.clone({
      setHeaders: {
        ...(request.method === "POST" &&
        (request.url.indexOf("documents") > -1 || request.url.indexOf("text-analyze") > -1)
          ? { Accept: "multipart/form-data" }
          : { "Content-Type": "application/json" }),
        ...(currentUser?.token && { Authorization: `Bearer ${currentUser.token}` }),
      },
    });
    return next.handle(request);
  }
}
