import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, NavigationEnd, Router } from "@angular/router";
import { AppStore } from "app/_shared/stores/app.store";
import { environment } from "environments/environment";
import { combineLatest, filter, map, Observable } from "rxjs";

@Component({
  selector: "app-full",
  templateUrl: "./full.component.html",
  styleUrls: [],
})
export class FullComponent implements OnInit {
  isCollapsed: boolean;
  hasError = false;
  status: number;
  loading: boolean = true;

  hasPermission = false;

  isDevelopment = environment.development;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appStore: AppStore,
  ) {
    combineLatest([this.getRouteData(), this.appStore.permissions$]).subscribe({
      next: ([data, permissions]) => {
        this.hasPermission = !data["permission"] || permissions.includes(data["permission"]);
      },
      error: () => {},
    });
  }

  ngOnInit(): void {
    this.appStore.isLoading$.subscribe((loading) => {
      this.loading = loading;
    });
    this.appStore.isCollapsed$.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
    this.appStore.error$.subscribe((res) => {
      this.hasError = res.hasError;
      this.status = res.status;
    });
  }

  getRouteData(): Observable<Data> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route.snapshot),
      map((rt) => {
        while (rt.firstChild) {
          rt = rt.firstChild;
        }
        return rt;
      }),
      map((rt) => rt.data),
    );
  }

  reload(): void {
    window.location.reload();
  }
}
