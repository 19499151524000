import { IAddress } from "app/_shared/types/models/address";
import { EProductUnit } from "app/_shared/types/models/product";
import { getNameByAlpha3 } from "assets/data/countries";
import { format, isAfter, isBefore, parseISO, startOfDay, endOfDay } from "date-fns";
import { saveAs } from "file-saver";
import { isEqual, isNumber, sortBy } from "lodash";
import { customAlphabet } from "nanoid";
import { parse, unparse } from "papaparse";
import { Observable } from "rxjs";

const nanoid = customAlphabet("1234567890", 5);

export const isInRange = (validFrom: string, validThrough: string) => {
  const currentDate = new Date();

  const isValidFrom = !validFrom || isBefore(startOfDay(parseISO(validFrom)), currentDate);
  const isValidThrough = !validThrough || isAfter(endOfDay(parseISO(validThrough)), currentDate);

  return isValidFrom && isValidThrough;
};

export const formatPrice = (price: number, currency: string = "USD"): string => {
  if (price == null) return "";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(price);
};

export const formatDate = (date: string | Date): string => {
  if (!date) return "";
  if (typeof date === "string") {
    return format(parseISO(date), "dd/MM/yyyy");
  }
  return format(date, "dd/MM/yyyy");
};

export const formatQuantity = (number: number, unit: EProductUnit) => {
  if (number == null) return "";
  if (!unit) return formatNumber(number);
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(number) + (" " + unit);
};

export const formatNumber = (number: number) => {
  if (number == null) return "";
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(number);
};

export const formatPercent = (number: number) => {
  if (number == null) return "";
  return `${new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number)}%`;
};

export const removeUnderscore = (value: string): string => {
  if (!value) return "";
  return value.replace(/_/g, " ");
};

export const getUniqId = (key: string) => `${key}_${nanoid()}`;

export const isArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = sortBy(arr1, (obj) => JSON.stringify(obj));
  const sortedArr2 = sortBy(arr2, (obj) => JSON.stringify(obj));

  return isEqual(sortedArr1, sortedArr2);
};

export const getDayDate = (date: Date): Date => {
  if (!date) return date;
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const formatAddress = (address: IAddress, sameLine: boolean = false) => {
  if (!address) {
    return "";
  }

  const separator = sameLine ? " " : "\n";

  const { street = "", zipCode = "", city = "", region = "", country = "" } = address;

  const secondLineParts = [zipCode, city, region].filter((part) => part !== "");
  const secondLine = secondLineParts.join(" / ");

  const thirdLine = getNameByAlpha3(country);
  return [street ? street + separator : "", secondLine ? secondLine + separator : "", thirdLine].join("");
};

export const saveCsv = (data: any[], fileName: string) => {
  const csvString = unparse(data);
  const blob = new Blob([csvString], { type: "text/csv" });
  saveAs(blob, `${fileName}.csv`); // File name
};

export const parseCsv = <T>(file: File) => {
  return new Observable<T[]>((subscriber) => {
    parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: function (results) {
        subscriber.next((results?.data ?? []) as T[]);
        subscriber.complete();
      },
      error: function (err) {
        subscriber.error(err);
      },
    });
  });
};

export const alwaysNumber = (number: number) => {
  return isNumber(number) ? number : 0;
};
