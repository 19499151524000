import { NgOptimizedImage, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import en from "@angular/common/locales/en";
import { ErrorHandler, NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import * as Sentry from "@sentry/angular";

import { IconDefinition } from "@ant-design/icons-angular";
import * as AllIcons from "@ant-design/icons-angular/icons";
import { GlobalErrorHandler } from "app/_shared/interceptor/error.interceptor";
import { HeaderInterceptor } from "app/_shared/interceptor/header.interceptor";

import { NgZorroModule } from "app/_shared/ng-zorro.module";
import { SharedModule } from "app/_shared/shared.module";
import { autoTips } from "app/_shared/utils/form.utils";

import { NZ_CONFIG, NzConfig } from "ng-zorro-antd/core/config";
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n";
import { NzIconModule } from "ng-zorro-antd/icon";

import { NgProgressModule } from "ngx-progressbar";
import { NgProgressRouterModule } from "ngx-progressbar/router";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { BlankComponent } from "./layouts/blank/blank.component";
import { FullComponent } from "./layouts/full/full.component";

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);

// The Factory function
const ngZorroConfig: NzConfig = { form: { nzAutoTips: autoTips } };

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent, BlankComponent, FullComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NzIconModule.forRoot(icons),
    NgZorroModule,
    SharedModule,
    NgOptimizedImage,
    NgProgressModule,
    NgProgressRouterModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
