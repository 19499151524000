import { Injectable } from "@angular/core";
import { createStore, select, setProps, withProps } from "@ngneat/elf";
import { localStorageStrategy, persistState } from "@ngneat/elf-persist-state";
import { Permission } from "app/_shared/types/models/role";
import { AppUser } from "app/_shared/types/models/user";
import { debounceTime } from "rxjs";

interface AppState {
  isLoading: boolean;
  isCollapsed: boolean;
  user: AppUser;
  permissions: Permission[];
  error: boolean;
  status: number;
}

export const store = createStore(
  { name: "app" },
  withProps<AppState>({
    isLoading: true,
    isCollapsed: false,
    user: null,
    permissions: [],
    error: false,
    status: null,
  }),
);

export const persist = persistState(store, {
  key: "app",
  storage: localStorageStrategy,
  source: () => store.pipe(debounceTime(1000)),
});

@Injectable({ providedIn: "root" })
export class AppStore {
  user = () => store.getValue().user;
  permissions = () => store.getValue().permissions;
  hasError = () => !!store.getValue().error;

  isLoading$ = store.pipe(select((state) => state.isLoading));
  isCollapsed$ = store.pipe(select((state) => state.isCollapsed));
  error$ = store.pipe(select((state) => ({ hasError: state.error, status: state.status })));
  permissions$ = store.pipe(select((state) => state.permissions));
  user$ = store.pipe(select((state) => state.user));

  setState(state: Partial<AppState>) {
    store.update(setProps(state));
  }
}
